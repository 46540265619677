import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { verifyEmail, updateUserProfile, userLogout } from '../store/actions/userActions';
import buttonStyles from './button.module.scss';
import { IconButton } from '@mui/material';
import profileStyles from './profile.module.scss'; 
import ProfileIcon from "../assets/ProfileIcon.png";
import VerifiedIcon from '@mui/icons-material/Verified';
import NotVerifiedIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';

const UserProfile = (props) => {
  const { userId, username, email, dateRegistered, emailVerified, userLogout, verifyEmail, updateUserProfile } = props;
  const [editEmail, setEditEmail] = useState(false);
  const [newEmail, setNewEmail] = useState(email); // Track changes to the email field
  const [password, setPassword] = useState(''); // Track user password input
  const [actionMessage, setActionMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // Track the type of the message
  const [isLoading, setIsLoading] = useState(false); // Track loading state

  useEffect(() => {
    setNewEmail(email); // Reset newEmail state if email changes
  }, [email, emailVerified]);

  const handleVerifyEmail = () => {
    verifyEmail()
      .then(() => {
        setActionMessage('Check Your Email. Verification link sent!');
        setMessageType('success');
      })
      .catch((error) => {
        setActionMessage('Failed to send verification email. Please try again later.');
        setMessageType('error');
      });
  };

  const toggleEditMode = () => {
    setEditEmail(!editEmail);
  };

  const handleSaveChanges = async () => {
    if (!newEmail) {
      setActionMessage('Please enter a valid email address.');
      setMessageType('error');
      return;
    }

    if (!password) {
      setActionMessage('Please enter your password to confirm changes.');
      setMessageType('error');
      return;
    }

    setIsLoading(true); 

    try {
      await updateUserProfile(userId, newEmail, password);
      setEditEmail(false); // Reset edit mode after saving changes
      setActionMessage('Profile updated successfully.');
      setMessageType('success');
    } catch (error) {
      setActionMessage(error.message || 'Failed to update profile. Please try again.');
      setMessageType('error');
    } finally {
      setIsLoading(false); 
    }
  };

  // Utility function to format the date
  const formattedDate = dateRegistered ? new Date(dateRegistered).toLocaleDateString() : '';

  return (
    <div className={profileStyles.profileContainer}>
      {actionMessage && (
        <p
          className={`${profileStyles.actionMessage} ${
            messageType === 'success' ? profileStyles.successMessage : profileStyles.errorMessage
          }`}
        >
          {actionMessage}
        </p>
      )}
      <div>
        <img src={ProfileIcon} alt="Profile Icon" className={profileStyles.profileIcon} />
      </div>
      <div className={profileStyles.profileInfo}>
        <label>Email:</label>
        {editEmail ? (
          <>
            <div>
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                className={profileStyles.editInput}
              />
            </div>
            <label>Password:</label>
            <div>
              <input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                className={profileStyles.passwordInput}
              />
            </div>
          </>
        ) : (
          <span className={profileStyles.value}>{newEmail}</span>
        )}
        <span className={profileStyles.verificationStatus}>
          {emailVerified ? (
            <>
              <IconButton>
                <VerifiedIcon className={profileStyles.verifiedIcon} />
                <div className={buttonStyles.iconText}>Verified</div>
              </IconButton>
            </>
          ) : (
            <>
              <IconButton><NotVerifiedIcon className={profileStyles.notVerifiedIcon} /> 
              <div className={buttonStyles.iconText}>Unverified</div></IconButton>
              <div className={profileStyles.verifyEmail}>
                {!editEmail && ( 
                  <button className={profileStyles.verifyButton} onClick={handleVerifyEmail}>
                    Verify Email
                  </button>
                )}
                <button className={profileStyles.editButton} onClick={toggleEditMode}>
                  {editEmail ? 'Cancel' : 'Edit Email'}
                </button>
              </div>
            </>
          )}
        </span>
      </div>
      <div className={profileStyles.profileInfo}>
        <label>Username:</label>
        <span className={profileStyles.value}>{username}</span>
      </div>
      <div className={profileStyles.profileInfo}>
        <label>Date Registered:</label>
        <span className={profileStyles.value}>{formattedDate}</span>
      </div>
      <div className={profileStyles.profileActions}>
        <button onClick={userLogout}>Logout</button>
        {editEmail && (
          <>
            <button onClick={handleSaveChanges} disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : 'Save Changes'}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  username: state.user.username,
  email: state.user.email,
  dateRegistered: state.user.dateRegistered,
  emailVerified: state.user.emailVerified,
});

const mapDispatchToProps = (dispatch) => ({
  verifyEmail: () => dispatch(verifyEmail()),
  userLogout: () => dispatch(userLogout()),
  updateUserProfile: (userId, newEmail, password) => dispatch(updateUserProfile(userId, newEmail, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
