const PrivacyPolicy = `
Privacy Policy
Effective Date: July, 2024

1. Introduction

Welcome to Cubana Chart! Your privacy is of utmost importance to us. This Privacy Policy outlines the types of information we collect, how we use and protect it, and your rights regarding your personal information.

2. Information We Collect

User Email: When you register or use our services, we collect your email address. This is the only personally identifiable information we collect.

3. How We Use Your Information

We use the collected email addresses for the following purposes:

(a) Account Creation and Management: To create and manage your user account.
(b) Communication: To send you important information regarding your account, updates, and notifications about our services.
(c) Support: To provide customer support and respond to your inquiries.
(d) Improvement: To improve our services and provide a better user experience.

4. How We Protect Your Information

We are committed to ensuring the security of your personal information. We implement a variety of security measures to maintain the safety of your email address when you enter, submit, or access your personal information.

5. Sharing Your Information

We do not sell, trade, or otherwise transfer your email address to outside parties. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.

6. Your Rights

You have the following rights regarding your email address:

(a) Access: You have the right to request access to the email address we hold about you.
(b) Correction: You have the right to request correction of your email address if it is incorrect or outdated.
(c) Deletion: You have the right to request the deletion of your email address from our systems. However, this may affect your ability to use our services.

7. Changes to Our Privacy Policy

We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.

8. Contact Us

If you have any questions about this Privacy Policy, please contact us at:

Email: support@cubanachart.com

By using our services, you consent to our Privacy Policy.

Thank you for using Cubana Chart!
`;

export default PrivacyPolicy;
