import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../configs/firebase/config';

export const fetchAllAds = () => async (dispatch, getState) => {
 // console.log("fetchAllAds action called"); // Add this log
  
  const { loading } = getState().ads;
  if (loading) return;

  dispatch({ type: 'FETCH_ALL_ADS_REQUEST' });

  try {
    const adsQuery = query(collection(db, 'Ads'), where('status', '==', 'running'));
    const querySnapshot = await getDocs(adsQuery);

    if (!querySnapshot.empty) {
      const allAds = querySnapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title,
        adTag: doc.data().adTag,
        date: doc.data().date,
        type: doc.data().type,
      }));

     // console.log("Fetched ads:", allAds); 

      dispatch({
        type: 'FETCH_ALL_ADS_SUCCESS',
        payload: allAds,
      });
    } else {
     // console.log("No ads found in database"); 
      throw new Error('No Ads for the selected period');
    }
  } catch (error) {
   //console.error("Error fetching ads:", error.message); 
    dispatch({
      type: 'FETCH_ALL_ADS_FAILURE',
      error: error.message,
    });
  }
};
