import React from 'react';
import TermsAndConditions from './TermsAndConditions';

const TermsAndConditionsPage = () => (
  <div style ={{margin: '10px', padding:'10px'}}>

    <h1>Terms and Conditions</h1>
    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      {TermsAndConditions}
      </pre>
  </div>
);

export default TermsAndConditionsPage;
