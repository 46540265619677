import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import TikTokIcon from "../assets/socials/TikTokIcon";
import aboutStyles from "./about.module.scss";

const About = () => {
  return (
    <>
      <div className={aboutStyles.aboutContainer}>
        <h3>About Cubana Chart</h3>
        <p>
          Cubana Charts is a music chart that lists the hottest songs of each week reigning within clubs, bars, and lounges owned by the Cubana Group, Nigeria's foremost hospitality and entertainment company.
        </p>
        <p>
          It is organically sorted and ranked, reflecting the true musical taste of the people on the ground.
        </p>
        <p>Follow us on our social media pages to never miss the top countdowns and stay updated with Cubana Chart.</p>   

        <div className={aboutStyles.socialLinks}>
          <div className={aboutStyles.socialLink}>
            <Tooltip title="Facebook">
              <a href="https://facebook.com/cubanacharts" target="_blank" rel="noreferrer" className={aboutStyles.facebookLink}>
                <IconButton aria-label="Facebook">
                  <FacebookIcon className={aboutStyles.facebookIcon} />
                </IconButton>
                <span className={aboutStyles.facebookText}>Facebook</span>
              </a>
            </Tooltip>
          </div>
          <div className={aboutStyles.socialLink}>
            <Tooltip title="Twitter">
              <IconButton href="https://twitter.com/cubana_chart" target="_blank" rel="noreferrer" aria-label="Twitter">
                <TwitterIcon className={aboutStyles.twitterIcon} />
              </IconButton>
            </Tooltip>
            <a href="https://twitter.com/cubana_chart" target="_blank" rel="noreferrer" className={aboutStyles.twitterText}>Twitter</a>
          </div>
          <div className={aboutStyles.socialLink}>
            <Tooltip title="Instagram">
              <IconButton href="https://instagram.com/cubanachart" target="_blank" rel="noreferrer" aria-label="Instagram">
                <InstagramIcon className={aboutStyles.instagramIcon} />
              </IconButton>
            </Tooltip>
            <a href="https://instagram.com/cubanachart" target="_blank" rel="noreferrer" className={aboutStyles.instagramText}>Instagram</a>
          </div>
          <div className={aboutStyles.socialLink}>
            <Tooltip title="TikTok">
              <IconButton href="https://tiktok.com/@cubanachart" target="_blank" rel="noreferrer" aria-label="TikTok">
                <TikTokIcon className={aboutStyles.tiktokIcon} />
              </IconButton>
            </Tooltip>
            <a href="https://tiktok.com/@cubanachart" target="_blank" rel="noreferrer" className={aboutStyles.tiktokText}>TikTok</a>
          </div>
        </div>
      </div>
      <div className={aboutStyles.footerContainer}>
        <div>
        <h5 className={aboutStyles.footerText}>
          The entirety of this site is protected by copyright ©2024 Cubana Chart & The Cubana Group of Companies.
        </h5>
        </div>
        <div>
        <h6 className={aboutStyles.footerLinks}>
          <a href="/terms-and-conditions"><u>Terms & Conditions</u></a>
          <a href="/privacy-policy"><u>Privacy Policy</u></a>
        </h6>
        </div>
      </div>
    </>
  );
};

export default About;
