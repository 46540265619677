import React from "react";
import { userLogout } from "../store/actions/userActions";
import { connect } from "react-redux";
import NotActive from "./notActive";
import Active from "./active";

const Render = (props) => {

  if (props.statut.active) {
    return <Active {...props} logic={props.isOpen} />;
  }
  return <NotActive logic={props.isOpen} />;
};

const mapStateToProps = (state) => {
  return {
    statut: state.statut,
    userId: state.statut.userId,
    username: state.statut.username,
    likedTracks: state.statut.likedTracks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(userLogout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Render);
