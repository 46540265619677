import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from "./components/store/rootReducer";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./components/assets/skeleton.scss";
import "./components/nav/menu.scss";

const store = configureStore({
  reducer: rootReducer,
 // middleware: [...getDefaultMiddleware(), thunk], // Use thunk in the middleware array
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();


