import React, { useState, useEffect } from "react";
import modalStyles from "./modal.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux"; 
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { clearErrors } from "../store/actions/clearErrors"; 
import queryString from 'query-string';

const Modal = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch(); 
  const slug = `/${props.type}`;
  const [showPassword, setShowPassword] = useState(false);
  const [formType, setFormType] = useState(props.type);
  const [oobCode, setOobCode] = useState(null);

  useEffect(() => {
    const { mode, oobCode } = queryString.parse(location.search);
    if (mode === 'resetPassword') {
      setFormType('resetpassword');
      setOobCode(oobCode);
    } else {
      setFormType(props.type);
    }
  }, [location.search, props.type]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formType === 'resetpassword') {
      props.handleSubmit(e, oobCode);
    } else {
      props.handleSubmit(e);
    }
  };

  const handleClose = () => {
    dispatch(clearErrors()); 
    navigate("/");
  };

  return (
    location.pathname === slug && (
      <div className={modalStyles.container}>
        <div className={modalStyles.modal}>
          <button className={modalStyles.close} onClick={handleClose}>
            &times;
          </button>
          {formType === "login" && (
            <div className={modalStyles.login}>
              <div className={modalStyles.loginTriangle}></div>
              <h2 className={modalStyles.loginHeader}></h2>
              <span className={modalStyles.error}>{props.errors.login}</span>
              <form className={modalStyles.loginContainer} onSubmit={handleSubmit}>
                <p>
                  <input name="email" type="email" placeholder="Email" onChange={props.handleChange} required />
                </p>
                <p style={{ position: 'relative' }}>
                  <input
                    onChange={props.handleChange}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    style={{ paddingRight: '40px' }}
                    required
                  />
                  {showPassword ? (
                    <VisibilityOff className={modalStyles.toggleIcon} onClick={handleTogglePassword} />
                  ) : (
                    <Visibility className={modalStyles.toggleIcon} onClick={handleTogglePassword} />
                  )}
                </p>
                <p><input type="submit" value="Log in" /></p>
                <div className={modalStyles.actionsContainer}>
                <span className={modalStyles.forgotPassword} onClick={() => navigate("/forgotpassword")}>
                  Forgot Password?
                </span>
                <span className={`${modalStyles.forgotPassword} ${modalStyles.signupInstead}`} onClick={() => navigate("/signup")}>
                  Signup Instead?
                </span>
              </div>
              </form>
            </div>
          )}
          {formType === "signup" && (
            <div className={modalStyles.login}>
              <div className={modalStyles.loginTriangle}></div>
              <h2 className={modalStyles.loginHeader}></h2>
              <span className={modalStyles.error}>
                {props.errors.field && "Name and password should be six or more characters long"}
                {props.errors.repass && "Passwords don't match"}
                {props.errors.signup}
                {props.errors.username && "Name should be letters and numbers only!"}
              </span>
              <form className={modalStyles.loginContainer} onSubmit={handleSubmit}>
                <p>
                  <input name="username" type="text" placeholder="Name" onChange={props.handleChange} required />
                </p>
                <p>
                  <input name="email" type="email" placeholder="Email" onChange={props.handleChange} required />
                </p>
                <p style={{ position: 'relative' }}>
                  <input
                    onChange={props.handleChange}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    style={{ paddingRight: '40px' }}
                    required
                  />
                  {showPassword ? (
                    <VisibilityOff className={modalStyles.toggleIcon} onClick={handleTogglePassword} />
                  ) : (
                    <Visibility className={modalStyles.toggleIcon} onClick={handleTogglePassword} />
                  )}
                </p>
                <p style={{ position: 'relative' }}>
                  <input
                    onChange={props.handleChange}
                    name="repeatPass"
                    type={showPassword ? "text" : "password"}
                    placeholder="Repeat Password"
                    style={{ paddingRight: '40px' }}
                    required
                  />
                </p>
                <p><input type="submit" value="Sign up" /></p>
                <span className={modalStyles.forgotPassword} onClick={() => navigate("/login")}>Login Instead?</span>
              </form>
            </div>
          )}
          {formType === "forgotpassword" && (
            <div className={modalStyles.login}>
              <div className={modalStyles.loginTriangle}></div>
              <h2 className={modalStyles.loginHeader}></h2>
              <span className={modalStyles.error}>
                {props.errors.email && "Invalid email format"}
                {props.errors.forgotpassword}
              </span>
              <form className={modalStyles.loginContainer} onSubmit={handleSubmit}>
                <p>
                  <input name="email" type="email" placeholder="Email" onChange={props.handleChange} required />
                </p>
                <p><input type="submit" value="Reset Password" /></p>
                <span className={modalStyles.forgotPassword} onClick={() => navigate("/login")}>Back to Login?</span>
              </form>
            </div>
          )}
          {formType === "resetpassword" && (
            <div className={modalStyles.login}>
              <div className={modalStyles.loginTriangle}></div>
              <h4 className={modalStyles.loginHeader}>Update New Password</h4>
              <span className={modalStyles.error}>
                {props.errors.field && "Password should be six or more characters long"}
                {props.errors.repass && "Passwords don't match"}
                {props.errors.resetpassword}
              </span>
              <form className={modalStyles.loginContainer} onSubmit={handleSubmit}>
                <p style={{ position: 'relative' }}>
                  <input
                    onChange={props.handleChange}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="New Password"
                    style={{ paddingRight: '40px' }}
                    required
                  />
                  {showPassword ? (
                    <VisibilityOff className={modalStyles.toggleIcon} onClick={handleTogglePassword} />
                  ) : (
                    <Visibility className={modalStyles.toggleIcon} onClick={handleTogglePassword} />
                  )}
                </p>
                <p style={{ position: 'relative' }}>
                  <input
                    onChange={props.handleChange}
                    name="repeatPass"
                    type={showPassword ? "text" : "password"}
                    placeholder="Repeat Password"
                    style={{ paddingRight: '40px' }}
                    required
                  />
                </p>
                <p><input type="submit" value="Save New Password" /></p>
                <span className={modalStyles.forgotPassword} onClick={() => navigate("/login")}>Back to Login?</span>
              </form>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Modal;
