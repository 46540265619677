import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Select from 'react-select';
import { fetchAllCharts } from '../store/actions/chartActions';
import cardStyles from './cards.module.scss';

const Container = motion.div;
const Card = motion.div;

const ExploreCharts = ({ fetchAllCharts, allCharts, loading, error }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    fetchAllCharts();
  }, [fetchAllCharts]);

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption.value);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };

  // Filter charts by the selected year and month
  const filteredCharts = allCharts.filter((chart) => {
    const chartDate = new Date(chart.date);
    const chartYear = chartDate.getFullYear();
    const chartMonth = chartDate.getMonth() + 1;
    return chartYear === selectedYear && chartMonth === selectedMonth;
  });

  // Generate year options for the dropdown
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let year = 2024; year <= currentYear; year++) {
    yearOptions.push({ value: year, label: year.toString() });
  }

  // Generate month options for the dropdown
  const monthOptions = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
  ];

  const chartsCards = filteredCharts.map((chart) => (
    <Card
      key={chart.id}
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 50, opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{ height: "100%", width: "100%" }}
    >
      <Link to={`/chart/${chart.id}`}>
        <div className={cardStyles.card} style={{ backgroundColor: 'black' }}>
          <span style={{ color: 'white' }}>{chart.chartTitle}</span>
        </div>
      </Link>
    </Card>
  ));

  return (
    <div className={cardStyles.wrapper}>
      <Container>
        <div style={{ marginBottom: '1em', display: 'flex', gap: '1em' }}>
          <Select
            options={yearOptions}
            defaultValue={yearOptions.find(option => option.value === selectedYear)}
            onChange={handleYearChange}
            isSearchable={false}
            placeholder="Select Year"
          />
          <Select
            options={monthOptions}
            defaultValue={monthOptions.find(option => option.value === selectedMonth)}
            onChange={handleMonthChange}
            isSearchable={false}
            placeholder="Select Month"
          />
        </div>
        <div className={cardStyles.gridContainer}>
          <div className={cardStyles.theGrid}>
            <AnimatePresence>
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                chartsCards
              )}
            </AnimatePresence>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allCharts: state.chart.allCharts,
  loading: state.chart.loading,
  error: state.chart.error,
});

const mapDispatchToProps = {
  fetchAllCharts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreCharts);
