import { collection, query, orderBy, limit, where, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../../../configs/firebase/config';

const calculateSongStatistics = async (songId, charts, latestChartPosition) => {
  let weeksOnChart = 0;
  let peakPosition = Infinity;
  let lastKnownPosition = null;

  charts.forEach(doc => {
    const songs = doc.data().songs || [];
    const song = songs.find(song => song.songId === songId);
    if (song) {
      weeksOnChart++;
      if (song.position < peakPosition) {
        peakPosition = song.position;
      }
      lastKnownPosition = song.position;
    }
  });

  // Check if the song is new based on whether it was found in previous charts
  const isNewSong = weeksOnChart === 0;

  // If the song is new, set its peak position to its position in the latest chart and weeksOnChart to 1
  if (isNewSong) {
    weeksOnChart = 1;
    peakPosition = latestChartPosition;
  }

  return {
    weeksOnChart,
    peakPosition: peakPosition === Infinity ? null : peakPosition,
    lastKnownPosition
  };
};

//fetch latest chart action
export const fetchLatestChart = () => async (dispatch) => {
  dispatch({ type: 'FETCH_LATEST_CHART_REQUEST' });

  try {
    const chartsQuery = query(collection(db, 'Charts'), orderBy('date', 'desc'), limit(20));
    const querySnapshot = await getDocs(chartsQuery);

    if (!querySnapshot.empty) {
      const latestChart = querySnapshot.docs[0];
      const previousCharts = querySnapshot.docs.slice(1); // All previous charts, up to 19

      const allPreviousSongs = new Set();
      previousCharts.forEach(doc => {
        const songs = doc.data().songs || [];
        songs.forEach(song => allPreviousSongs.add(song.songId));
      });

      const latestSongsWithStats = await Promise.all(latestChart.data().songs.map(async song => {
        const stats = await calculateSongStatistics(song.songId, previousCharts, song.position);
        return { ...song, ...stats };
      }));
      
      

      dispatch({
        type: 'FETCH_LATEST_CHART_SUCCESS',
        payload: {
          latestChart: { ...latestChart.data(), id: latestChart.id, songs: latestSongsWithStats },
          allPreviousSongs: Array.from(allPreviousSongs),
        }
      });
    } else {
      throw new Error('Not enough charts found');
    }
  } catch (error) {
    dispatch({
      type: 'FETCH_LATEST_CHART_FAILURE',
      error: error.message
    });
  }
};


// Action to fetch chart by ID
export const fetchChartByID = (chartId) => async (dispatch) => {
  dispatch({ type: 'FETCH_CHART_BY_ID_REQUEST' });

  try {
    const chartDocRef = doc(db, 'Charts', chartId);
    const chartDoc = await getDoc(chartDocRef);

    if (!chartDoc.exists()) {
      throw new Error('Chart not found');
    }

    const currentChart = { id: chartDoc.id, ...chartDoc.data() };
    const currentChartDate = currentChart.date;

    // Query to get the previous chart
    const chartsCollectionRef = collection(db, 'Charts');
    const previousChartQuery = query(
      chartsCollectionRef,
      where('date', '<', currentChartDate),
      orderBy('date', 'desc'),
      limit(1)
    );

    const previousChartSnapshot = await getDocs(previousChartQuery);
    let previousChart = null;

    if (!previousChartSnapshot.empty) {
      const previousChartDoc = previousChartSnapshot.docs[0];
      previousChart = { id: previousChartDoc.id, ...previousChartDoc.data() };
    }

    // Query to get the last 20 charts
    const allPreviousChartsQuery = query(
      chartsCollectionRef,
      orderBy('date', 'desc'),
      limit(20)
    );
    const allPreviousChartsSnapshot = await getDocs(allPreviousChartsQuery);

    const previousCharts = allPreviousChartsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Function to calculate statistics for each song
    const calculateSongStatistics = (songId) => {
      let weeksOnChart = 0;
      let peakPosition = Infinity;
      let lastKnownPosition = null;

      previousCharts.forEach(chart => {
        const songs = chart.songs || [];
        const song = songs.find(song => song.songId === songId);
        if (song) {
          weeksOnChart++;
          if (song.position < peakPosition) {
            peakPosition = song.position;
          }
          lastKnownPosition = song.position;
        }
      });

      return {
        weeksOnChart,
        peakPosition: peakPosition === Infinity ? null : peakPosition,
        lastKnownPosition
      };
    };

    // Process the latest chart's songs and add statistics
    const latestSongsWithStats = await Promise.all(
      currentChart.songs.map(async (song) => {
        const stats = calculateSongStatistics(song.songId);

        // If the song is new on the chart
        if (stats.weeksOnChart === 0) {
          stats.peakPosition = song.position;
          stats.weeksOnChart = 1;
        }

        return { ...song, ...stats };
      })
    );

    dispatch({
      type: 'FETCH_CHART_BY_ID_SUCCESS',
      payload: {
        currentChart: { ...currentChart, songs: latestSongsWithStats },
        previousChart,
      }
    });
  } catch (error) {
    dispatch({
      type: 'FETCH_CHART_BY_ID_FAILURE',
      error: error.message
    });
  }
};


// Fetch all charts
export const fetchAllCharts = () => async (dispatch) => {
  dispatch({ type: 'FETCH_ALL_CHARTS_REQUEST' });

  try {
    // Construct a query to fetch charts, ordered by date descending
    const chartsQuery = query(collection(db, 'Charts'), orderBy('date', 'desc'));

    // Fetch the charts using the constructed query
    const querySnapshot = await getDocs(chartsQuery);

    // Check if there are charts returned
    if (!querySnapshot.empty) {
      const allCharts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        chartTitle: doc.data().chartTitle,
        date: doc.data().date,
      }));

      // Dispatch a success action with the fetched chart data
      dispatch({
        type: 'FETCH_ALL_CHARTS_SUCCESS',
        payload: allCharts,
      });
    } else {
      throw new Error('No charts for the selected period');
    }
  } catch (error) {
    dispatch({
      type: 'FETCH_ALL_CHARTS_FAILURE',
      error: error.message,
    });
  }
};
