import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchLatestChart } from '../store/actions/chartActions';
import chartListStyles from './chartList.module.scss';
import Skeleton from '../assets/skeleton';
import PlaylistTable from './playlistTable';
import { motion } from 'framer-motion';
import { IconButton } from '@mui/material';
import { Share } from '@mui/icons-material';
import buttonStyles from './button.module.scss';
import ShareModal from './shareModal';

const Container = motion.div;

const SkeletonContainer = () => (
  <div style={{ marginTop: '1em', height: '70vh', opacity: 0.2 }}>
    <Skeleton times={14} />
  </div>
);

const ChartsList = ({ latestChart, loading, error, fetchLatestChart, userSetTrack }) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchLatestChart();
  }, [fetchLatestChart]);

  const handleShareClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (loading) {
    return (
      <div className={chartListStyles.tableContainer}>
        <div style={{ position: 'relative' }}>
          <SkeletonContainer />
        </div>
      </div>
    );
  }

  if (error) {
    return <div className={chartListStyles.err}>Network Connection Error. {error}.</div>;
  }

  if (!latestChart) {
    return <div>No chart available.</div>;
  }

  const { chartTitle: latestChartTitle, songs: latestSongs } = latestChart;

  return (
    <Container>
      <div className={chartListStyles.chartTitleContainer}>
        <h2>
          {latestChartTitle}
          <IconButton onClick={handleShareClick} className={buttonStyles.iconButton}>
            <Share />
            <div className={buttonStyles.iconText}>Share</div>
          </IconButton>
        </h2>
      </div>
      <PlaylistTable latestSongs={latestSongs} userSetTrack={userSetTrack} />
      <ShareModal open={openModal} handleClose={handleCloseModal} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  latestChart: state.chart.latestChart,
  loading: state.chart.loading,
  error: state.chart.error,
});

const mapDispatchToProps = {
  fetchLatestChart,
  userSetTrack: (index) => ({ type: 'USER_SET_TRACK', index }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartsList);
