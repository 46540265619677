const TermsAndConditions = `
Terms and Conditions

Last updated: July,2024

Welcome to Cubana Charts. Please read these terms and conditions carefully before using our service.

1. Introduction

These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with Cubana Charts website (the "Service") operated by Cubana Group ("us", "we", or "our").

2. Acceptance of Terms

By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.

3. Accounts

When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.

4. Termination

We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.

5. Intellectual Property

The Service and its original content, features, and functionality are and will remain the exclusive property of Cubana Group and its licensors.

6. Limitation of Liability

In no event shall Cubana Chart or its Parent Company, The Cubana Group, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.

7. Governing Law

These Terms shall be governed and construed in accordance with the laws of The Federal Republic of Nigeria, without regard to its conflict of law provisions.

8. Changes

We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

9. Contact Us

If you have any questions about these Terms, please contact us at support@cubanachart.com.
`;

export default TermsAndConditions;
