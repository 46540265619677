import React from "react";
import modalStyles from "./modal.module.scss";

const Modal = (props) => {

  const handleModalClick = (e) => {
    e.stopPropagation(); 
  };

  return (
    <div className={modalStyles.container} onClick={props.handleClose}>
      <div className={modalStyles.modal} onClick={handleModalClick}>
        <button className={modalStyles.close} onClick={props.handleClose}>
          &times;
        </button>
        {props.type === "spotify" && (
          <div className={modalStyles.spotify}>
            {props.children}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
