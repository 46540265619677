import React from 'react';
import './Preloader.scss'; 

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="spinner"></div>
    </div>
  );
};

export default Preloader;
