import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import NavBar from './components/nav/navBar';
import Main from './components/main/main';
import ChartsList from './components/main/chartsList';
import Login from "./components/modals/Login";
import Signup from "./components/modals/Signup";
import ForgotPassword from "./components/modals/ForgotPassword";
import ResetPassword from "./components/modals/ResetPassword";
import VerifyEmail from './components/main/verify-email';
import UserProfile from './components/main/profile';
import AccountAction from './components/main/account-action';
import UserFavs from './components/main/userFav';
import ChartByID from './components/main/exploreChartList';
import TermsAndConditionsPage from './components/main/terms-and-conditions';
import PrivacyPolicyPage from './components/main/privacy-policy';
import { auth, db, doc, getDoc } from "./configs/firebase/config";
import { fetchLatestChart } from './components/store/actions/chartActions';
import { AnimatePresence } from 'framer-motion';
import ExploreCharts from './components/main/explore-charts';
import About from './components/main/about';
import layout from './App.module.scss';
import NativeAdComponent from './components/main/ads/nativeAdComponent'; // Import the component

class App extends Component {
  state = {
    secured: null 
  };

  componentDidMount() {
    this.props.fetchLatestChart();
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDoc = doc(db, "users", user.uid);
          const profile = await getDoc(userDoc);
          
          if (profile.exists()) {
            const { id, email, username, dateRegistered, likedTracks, emailVerified } = profile.data();
            this.setState({ secured: false });
            this.props.userIsActive(id, email, username, dateRegistered, likedTracks, emailVerified);
          } else {
            this.setState({ secured: true });
            this.props.userIsNotActive();
          }
        } catch (error) {
          this.setState({ secured: true });
          this.props.userIsNotActive();
        }
      } else {
        this.setState({ secured: true });
        this.props.userIsNotActive();
      }
    });
  }

  render() {
    const { secured } = this.state;

    return (
      <div style={{ overflow: 'hidden', height: '100vh', width: '100vw', position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Router>
          <NavBar />
          <div className={layout.label}>
            <p>
              Top hit songs, club bangers as curated by DJ's, Music Lovers in & around Clubs, Bars, Lounges & Hotels owned by The Cubana Group.
            </p>
          </div>
          
          <div className={layout.container}>
            <div className={layout.section}>
              <Main secured={secured} />
            </div>
            {/* Place NativeAdComponent here */}
            <NativeAdComponent />
          </div>
          <AnimatePresence>
            <ModalRoutes secured={secured} />
          </AnimatePresence>
        </Router>
      </div>
    );
  }
}

const ModalRoutes = ({ secured }) => {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<ChartsList />} />
      <Route path="/login" element={<Login secured={secured} />} />
      <Route path="/signup" element={<Signup secured={secured} />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/explore-charts" element={<ExploreCharts />} />
      <Route path="/profile" element={secured ? <Navigate to="/login" /> : <UserProfile />} />
      <Route path="/verify-email" element={secured ? <Navigate to="/login" /> : <VerifyEmail />} />
      <Route path="/account-action" element={  <AccountAction />} />
      <Route path="/about" element={<About />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/chart/:chartId" element={<ChartByID />} />
      <Route path="/user/saved-tracks" element={secured ? <Navigate to="/login" /> : <UserFavs />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  statut: state.statut
});

const mapDispatchToProps = (dispatch) => ({
  fetchLatestChart: () => dispatch(fetchLatestChart()),
  userIsActive: (id, email, username, dateRegistered, likedTracks, emailVerified) =>
    dispatch({
      type: "USER_IS_ACTIVE",
      id,
      email,
      username,
      dateRegistered,
      likedTracks,
      emailVerified
    }),
  userIsNotActive: () => dispatch({ type: "USER_IS_NOT_ACTIVE" })
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
