import { combineReducers } from "redux";
import chartsReducer from "./reducers/chartsReducer";
import playlistReducer from "./reducers/playlistReducer";
import userReducer from "./reducers/userReducer";
import adReducer from "./reducers/adReducer";

const rootReducer = combineReducers({
  user: userReducer,
  chart: chartsReducer,
  playlist: playlistReducer,
  ads: adReducer,
  statut: userReducer
});

export default rootReducer;
