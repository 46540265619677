import React from 'react';
import PrivacyPolicy from './PrivacyPolicy';

const PrivacyPolicyPage = () => (
  <div style ={{margin: '10px', padding:'10px'}}>
    <h1>Privacy Policy</h1>
    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      {PrivacyPolicy}
      </pre>
  </div>
);

export default PrivacyPolicyPage;
