const initialState = {
  latestChart: null,
  currentChart: null,
  previousChart: null,
  allCharts: [],
  allPreviousSongs: [],
  loading: false,
  error: null,
};

const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_LATEST_CHART_REQUEST':
    case 'FETCH_ALL_CHARTS_REQUEST':
    case 'FETCH_CHART_BY_ID_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'FETCH_LATEST_CHART_SUCCESS':
      return {
        ...state,
        loading: false,
        latestChart: action.payload.latestChart,
        previousChart: action.payload.previousChart,
        allPreviousSongs: action.payload.allPreviousSongs,
      };
    case 'FETCH_ALL_CHARTS_SUCCESS':
      return {
        ...state,
        allCharts: action.payload,
        loading: false,
      };
    case 'FETCH_CHART_BY_ID_SUCCESS':
      return {
        ...state,
        currentChart: action.payload.currentChart,
        previousChart: action.payload.previousChart,
        allPreviousSongs: action.payload.allPreviousSongs,
        loading: false,
      };
    case 'FETCH_LATEST_CHART_FAILURE':
    case 'FETCH_ALL_CHARTS_FAILURE':
    case 'FETCH_CHART_BY_ID_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default chartReducer;
