import React from 'react';

const TikTokIcon = ({ width = 24, height = 24, fill = 'currentColor', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M41 16.5a12.53 12.53 0 01-7.75-2.67 13.16 13.16 0 01-2.75-3.26 13.25 13.25 0 01-1.5-5.07h-4.75v21.66a6.57 6.57 0 11-6.24-6.67 6.61 6.61 0 012.75.57v-4.9a11.1 11.1 0 00-2.75-.34 11.31 11.31 0 1011.23 11.5V16.75a17.47 17.47 0 0010.01 3.09V16.5z" />
  </svg>
);

export default TikTokIcon;
