import React from "react";
import { NavLink } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import navStyles from "./nav.module.scss";

const Active = (props) => {
  const toggle = props.logic ? "open" : "closed";
  return (
    <ul className={"nav " + toggle}>
      <NavLink to="/about" className={({ isActive }) => isActive ? navStyles.active : undefined}>
        <li>About</li>
      </NavLink>
      <NavLink to="/explore-charts" className={({ isActive }) => isActive ? navStyles.active : undefined}>
        <li>Explore Charts</li>
      </NavLink>
      <NavLink to="/user/saved-tracks" className={({ isActive }) => isActive ? navStyles.active : undefined}>
        <li>Saved</li>
      </NavLink>
     
        <NavLink to="/profile" className={({ isActive }) => isActive ? navStyles.active : undefined}>
        <li className="user">
        <AccountCircle className="icon" />
          <span className="username">Hi, {props.username}</span>
          </li>
        </NavLink>
    </ul>
  );
};

export default Active;
