import React, { useState, useEffect } from "react";
import Modal from "./modal";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { userSignup } from "../store/actions/userActions";
import Preloader from "../main/preloader";

const Signup = (props) => {
  let navigate = useNavigate();

  const [userdata, setUserData] = useState({
    username: "",
    email: "",
    password: "",
    repeatPass: ""
  });
  const [errors, setErrors] = useState({ repass: false, field: false, email: false, username: false });
  const [cred, setCred] = useState(null);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateUsername = (username) => {
    const re = /^[a-zA-Z0-9]+$/;
    return re.test(username);
  };

  const handleSubmit = async (e) => {  
    e.preventDefault();
    setUserData({ ...userdata });
    const { username, email, password, repeatPass } = userdata;

    if (!validateUsername(username)) {
      return setErrors({ ...errors, username: true });
    }
    if (username.length < 5 || password.length < 6) {
      return setErrors({ ...errors, field: true });
    }
    if (!validateEmail(email)) {
      return setErrors({ ...errors, email: true });
    }
    if (password !== repeatPass) {
      return setErrors({ ...errors, repass: true });
    }

    const credentials = await props.signup(username, email, password); 
    setCred(credentials);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setErrors({ field: false, repass: false, email: false, username: false });
    setUserData({ ...userdata, [e.target.name]: value });
  };

  useEffect(() => {
    if (cred && !props.signupError) {
      navigate("/");
    }
  }, [cred, props.signupError, navigate]);

  return (
    <div>
      {props.isFetching && <Preloader />}
      <Modal
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        errors={{ ...errors, signup: props.signupError }}
        type={"signup"}
        isFetching={props.isFetching}
        {...props}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.user.isFetching,
  signupError: state.user.signupError,
});

const mapDispatchToProps = (dispatch) => ({
  signup: (username, email, password) => dispatch(userSignup(username, email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
