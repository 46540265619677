import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { userSaveTrack } from '../store/actions/userActions';
import Modal from '../modals/spotifyModal';
import { ArrowUpward, ArrowDownward, HorizontalRule, NewReleases } from '@mui/icons-material';
import HeadsetIcon from '@mui/icons-material/Headset';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import './playlistTable.scss';
import buttonStyles from './button.module.scss';
import { IconButton, Tooltip } from '@mui/material';
import AdComponent from './ads/AdComponent';

const PlaylistTable = ({ latestSongs = [], previousSongs = [], userId, likedTracks = [], saveTrack, userSetTrack, secured }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTrackId, setCurrentTrackId] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [promptTrackId, setPromptTrackId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (latestSongs) {
      const updatedTracks = latestSongs.map((latestSong) => {
        const prevPos = latestSong.lastKnownPosition !== null ? latestSong.lastKnownPosition : 'New';
        const movement = latestSong.lastKnownPosition !== null ? latestSong.position - latestSong.lastKnownPosition : '-';
  
        return {
          ...latestSong,
          prevPos,
          movement,
          isNew: latestSong.lastKnownPosition === null,
        };
      });
  
      setTracks(updatedTracks);
    }
  }, [latestSongs]);

  const getTrackIdFromUrl = (url) => {
    const urlObj = new URL(url);
    const pathParts = urlObj.pathname.split('/');
    return pathParts[pathParts.length - 1];
  };

  const openModal = (trackUrl) => {
    const trackId = getTrackIdFromUrl(trackUrl);
    setCurrentTrackId(trackId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentTrackId(null);
  };

  const handleSaveTrack = (track) => {
    if (!secured) {
      setShowLoginPrompt(true);
      setPromptTrackId(track.songId);
      setTimeout(() => {
        setShowLoginPrompt(false);
        setPromptTrackId(null);
      }, 3000);
    } else {
      setLoading(true);
      saveTrack(userId, track)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const getMovementIcon = (movement, isNew) => {
    if (isNew) {
      return <NewReleases style={{ color: 'orange', fontSize: 'small' }} />;
    } else if (movement < 0) {
      return <ArrowUpward style={{ color: 'green', fontSize: 'small' }} />;
    } else if (movement > 0) {
      return <ArrowDownward style={{ color: 'red', fontSize: 'small' }} />;
    } else if (movement === 0) {
      return <HorizontalRule style={{ color: 'grey', fontSize: 'small' }} />;
    } else {
      return <HorizontalRule style={{ color: 'grey', fontSize: 'small' }} />;
    }
  };

  const tracksInfo = tracks.map((track, index) => {
    const isTrackLiked = likedTracks.some((likedTrack) => likedTrack.songId === track.songId);

    return (
      <React.Fragment key={`${track.songId}-${index}`}>
        <motion.tr
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => userSetTrack(index)}
        >
          <th>{index + 1}
            <div className={`prevPos ${track.isNew ? 'new' : track.movement < 0 ? 'up' : track.movement > 0 ? 'down' : 'noChange'}`}>
              {getMovementIcon(track.movement, track.isNew)}
              {track.prevPos}
            </div>
          </th>
          <td>{track.songTitle}</td>
          <td>{track.artiste}</td>
          <td>{track.peakPosition || 'N/A'}</td>
          <td>{track.weeksOnChart || 'N/A'}</td>
          <td className={buttonStyles.buttonContainer}>
            <IconButton onClick={(e) => { e.stopPropagation(); openModal(track.songUrl); }} className={buttonStyles.iconButton}>
              <HeadsetIcon />
              <div className={buttonStyles.iconText}>Listen</div>
            </IconButton>
            <IconButton
              onClick={(e) => { e.stopPropagation(); if (!isTrackLiked) handleSaveTrack(track); }}
              className={buttonStyles.iconButton}
              disabled={isTrackLiked}
            >
              {isTrackLiked ? (
                <LibraryAddCheckIcon />
              ) : (
                <Tooltip
                  open={showLoginPrompt && promptTrackId === track.songId}
                  title="Login required to save song"
                  arrow
                  placement="top"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <LibraryAddIcon />
                </Tooltip>
              )}
              <div className={buttonStyles.iconText}>{isTrackLiked ? 'Saved' : 'Save'}</div>
            </IconButton>
          </td>
        </motion.tr>
        {index === 9 && (
          <tr key={`ad-row-${index}`}>
            <td colSpan="12">
              <AdComponent songCount={index + 1} /> {/* Ad shown only after the 10th song */}
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  });

  return (
    <>
      {loading && <div className="saveloader">Adding song to your saves...</div>}
      {tracks.length > 0 && (
        <table
          style={{ marginTop: -1 }}
          className="table table-condensed mobile-table"
        >
          <thead>
            <tr>
              <th>#<div className={'prevPos'}>prev. pos</div></th>
              <th>Song Title</th>
              <th>Artiste</th>
              <th>Peak Position</th>
              <th>Weeks on Chart</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <AnimatePresence>{tracksInfo}</AnimatePresence>
          </tbody>
        </table>
      )}
      {modalOpen && (
        <Modal type="spotify" handleClose={closeModal}>
          <iframe
            src={`https://open.spotify.com/embed/track/${currentTrackId}`}
            width="100%"
            height="380"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            title="Spotify track player"
          ></iframe>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  likedTracks: state.user.likedTracks || [],
  secured: state.statut.active,
});

const mapDispatchToProps = (dispatch) => ({
  saveTrack: (userId, track) => dispatch(userSaveTrack(userId, track)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistTable); 