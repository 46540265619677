import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { verifyEmailInFirestore } from '../store/actions/userActions';
import './verify-email.css'; 

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { isFetching, verifiedEmailSuccess, verifiedEmailError } = useSelector(state => state.user);

  useEffect(() => {
    const { oobCode } = queryString.parse(location.search);

    if (oobCode) {
      dispatch(verifyEmailInFirestore(oobCode));
    }
  }, [dispatch, location.search]);

  useEffect(() => {
    if (verifiedEmailSuccess) {
      alert('Email verified successfully!');
      navigate('/profile');
    } else if (verifiedEmailError) {
      if (verifiedEmailError === "Invalid or expired verification link.") {
        navigate('/profile');
      } else {
        navigate('/profile');
      }
    }
  }, [verifiedEmailSuccess, verifiedEmailError, navigate]);

  return (
    <div className="verify-email-container">
      {isFetching && <p>Verifying email...<span className="loader"></span></p>}
    </div>
  );
};

export default VerifyEmail;
