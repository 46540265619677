import React, { useState } from 'react';
import { connect } from 'react-redux';
import favStyles from './userFav.module.scss';
import buttonStyles from './button.module.scss';
import HeadsetIcon from '@mui/icons-material/Headset';
import Modal from '../modals/spotifyModal';
import { motion, AnimatePresence } from 'framer-motion';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { userRemoveTrack } from '../store/actions/userActions';

const Container = motion.div;
const Box = motion.div;

const UserFavs = ({ statut, secured, removeTrack }) => {
  const { likedTracks, userId, username } = statut;
  const style = { textAlign: 'center', margin: '2em' };
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTrackId, setCurrentTrackId] = useState(null);

  const handleRemoveTrack = (songId) => {
    removeTrack(userId, songId);
  };

  const getTrackIdFromUrl = (url) => {
    const urlObj = new URL(url);
    const pathParts = urlObj.pathname.split('/');
    return pathParts[pathParts.length - 1];
  };

  const openModal = (trackUrl) => {
    const trackId = getTrackIdFromUrl(trackUrl);
    setCurrentTrackId(trackId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentTrackId(null);
  };

  const table = likedTracks.map((track, index) => (
    <tr key={index}>
      <th>{index + 1}</th>
      <td>{track.songTitle}</td>
      <td>{track.artiste}</td>
      <td className={buttonStyles.buttonContainer}>
        <IconButton onClick={(e) => { e.stopPropagation(); openModal(track.songUrl); }} className={buttonStyles.iconButton}>
          <HeadsetIcon />
          <div className={buttonStyles.iconText}>Listen</div>
        </IconButton>
        <IconButton onClick={() => handleRemoveTrack(track.songId)} aria-label="Remove" className={buttonStyles.iconButton}>
          <Close />
          <div className={buttonStyles.iconText}>Remove</div>
        </IconButton>
      </td>
    </tr>
  ));

  return (
    secured === true && (
      <>
        <AnimatePresence>
          {likedTracks.length > 0 ? (
            <Container>
              <Box
                className={favStyles.header}
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -50, opacity: 0 }}
              >
                {username}'s saved tracks
              </Box>
              <div className={favStyles.tableWrapper}>
                <table className={`${favStyles.table} table`}>
                  <tbody>{table}</tbody>
                </table>
              </div>
            </Container>
          ) : (
            <motion.div style={style} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              You have no saved tracks yet
            </motion.div>
          )}
        </AnimatePresence>
        
        {modalOpen && (
          <Modal type="spotify" handleClose={closeModal}>
            <iframe
              src={`https://open.spotify.com/embed/track/${currentTrackId}`}
              width="100%"
              height="380"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              title="Spotify track player"
            ></iframe>
          </Modal>
        )}
      </>
    )
  );
};

const mapStateToProps = (state) => {
  return { statut: state.statut, secured: state.statut.active };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeTrack: (userId, songId) => dispatch(userRemoveTrack(userId, songId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFavs);
