import React from 'react';
import { Modal, IconButton } from '@mui/material';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
  TelegramShareButton,
  RedditShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  PinterestIcon
} from 'react-share';
import { ContentCopy } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './shareModal.module.scss';

const ShareModal = ({ open, handleClose }) => {
  const title = "Check out the top 20 songs of the week on Cubana Chart";
  const url = window.location.href;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
      toast.success('Link copied to clipboard!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      toast.error('Failed to copy the link!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
    <>
      <ToastContainer />
      <Modal open={open} onClose={handleClose}>
        <div className={styles.modalContent}>
          <h2>Share This Chart</h2>
          <div className={styles.iconContainer}>
            <FacebookShareButton url={url} quote={title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <FacebookMessengerShareButton url={url} quote={title}>
              <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <TwitterShareButton url={url} title={title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url={url} title={title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <TelegramShareButton url={url} title={title}>
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <PinterestShareButton url={url} title={title}>
              <PinterestIcon size={32} round />
            </PinterestShareButton>
            <EmailShareButton url={url} title={title}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            <RedditShareButton url={url} title={title}>
              <RedditIcon size={32} round />
            </RedditShareButton>
            <LinkedinShareButton url={url} title={title}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <IconButton onClick={copyToClipboard}>
              <ContentCopy />
              <div className={styles.iconText}><span>Copy Link</span></div>
            </IconButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShareModal;
