import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { userResetPassword } from '../store/actions/userActions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Preloader from '../main/preloader';
import Modal from './modal';

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');
  
  const [userdata, setUserData] = useState({ password: "", repeatPass: "" });
  const [errors, setErrors] = useState({ repass: false, field: false });

  useEffect(() => {
    if (!oobCode) {
      navigate("/login"); 
    }
  }, [oobCode, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, repeatPass } = userdata;

    // Reset errors
    setErrors({ repass: false, field: false });

    // Validation
    if (password.length < 6) {
      setErrors({ ...errors, field: true });
      return;
    }
    if (password !== repeatPass) {
      setErrors({ ...errors, repass: true });
      return;
    }

    await props.resetpassword(oobCode, password, navigate);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setErrors({ repass: false, field: false });
    setUserData({ ...userdata, [e.target.name]: value });
  };

  return (
    <div>
      {props.isFetching && <Preloader />}
      <Modal
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        errors={{ ...errors, resetpassword: props.ResetPasswordError }}
        type={"resetpassword"}
        isFetching={props.isFetching}
        {...props}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.user.isFetching,
  ResetPasswordError: state.user.ResetPasswordError,
});

const mapDispatchToProps = (dispatch) => ({
  resetpassword: (oobCode, password, navigate) => dispatch(userResetPassword(oobCode, password, navigate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
