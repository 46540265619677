import { CLEAR_ERRORS } from '../actions/clearErrors';
const initState = {
  userId: "",
  email: "",
  username: "",
  likedTracks: [],
  dateRegistered: null,
  emailVerified: false,
  errorMessage: "",
  loginError: "",
  signupError: "",
  ForgotPasswordError: "",
  ResetPasswordError: "",
  verifyEmailError: "",
  verifyEmailSuccess: false,
  verifiedEmailError: "",
  verifiedEmailSuccess: false,
  isFetching: false,
  active: false,
  mount: false,
  notification: false
  
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGNUP_START":
    case "LOGIN_START":
    case "LOGOUT_START":
    case "VERIFY_EMAIL_START":
    case "VERIFIED_EMAIL_START":
    case "FORGOT_PASSWORD_START":
    case "RESET_PASSWORD_START":
      return { ...state, isFetching: true };

    case "SIGNUP_SUCCESS":
      return {
        ...state,
        isFetching: false,
        userId: action.user.id,
        username: action.user.username,
        email: action.user.email,
        dateRegistered: action.user.dateRegistered,
        likedTracks: action.user.likedTracks || [],
        emailVerified: action.user.emailVerified,
        active: true,
        signupError: "",
        loginError: "",
        ForgotPasswordError: "",
        ResetPasswordError: ""
      };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        isFetching: false,
        userId: action.user.id,
        username: action.user.username,
        email: action.user.email,
        dateRegistered: action.user.dateRegistered,
        likedTracks: action.user.likedTracks || [],
        emailVerified: action.user.emailVerified,
        active: true,
        signupError: "",
        loginError: "",
        ForgotPasswordError: "",
        ResetPasswordError: ""
      };

    case "LOGOUT_SUCCESS":
      return {
        ...state,
        isFetching: false,
        userId: "",
        email: "",
        username: "",
        dateRegistered: null,
        likedTracks: [],
        emailVerified: false,
        active: false,
        signupError: "",
        loginError: "",
        ForgotPasswordError: "",
        ResetPasswordError: ""
      };

    case "VERIFY_EMAIL_SUCCESS":
      return{
        ...state, 
        isFetching: false, 
        verifyEmailSuccess: true,
      }

    case "VERIFIED_EMAIL_SUCCESS":
        return {
          ...state,
          isFetching: false,
          verifiedEmailSuccess: true,
          verifiedEmailError: "",
          emailVerified: action.payload, 
        };

    case "SIGNUP_ERROR":
      return { 
        ...state, 
        isFetching: false, 
        signupError: action.err 
      };

    case "LOGIN_ERROR":
      return { 
        ...state, 
        isFetching: false, 
        loginError: action.err 
      };

    case "LOGOUT_ERROR":
      return { 
        ...state, 
        isFetching: false, 
        errorMessage: action.err 
      };

    case "VERIFY_EMAIL_ERROR":
      return { ...state, 
        isFetching: false, 
        verifyEmailSuccess: false,
        verifyEmailError: action.err };

    case "VERIFIED_EMAIL_ERROR":
      return {
        ...state,
        isFetching: false,
        verifiedEmailSuccess: false,
        verifiedEmailError: action.error,
      };
    
    case "FORGOT_PASSWORD_SUCCESS":
      return {
        ...state,
        isFetching: false,
        ForgotPasswordError: ""
      };

    case "FORGOT_PASSWORD_ERROR":
      return {
        ...state,
        isFetching: false,
        ForgotPasswordError: action.err
      };
    case "RESET_PASSWORD_SUCCESS":
        return {
          ...state,
          isFetching: false,
          ResetPasswordError: ""
      };
  
    case "RESET_PASSWORD_ERROR":
        return {
          ...state,
          isFetching: false,
          ResetPasswordError: action.err
      };
      
    case CLEAR_ERRORS:
        return {
          ...state,
          signupError: "",
          loginError: "",
          ForgotPasswordError: "",
          errorMessage: ""
      };

      case "USER_IS_ACTIVE":
        return {
          ...state,
          active: true,
          userId: action.id,
          email: action.email,
          username: action.username,
          likedTracks: action.likedTracks || [],
          dateRegistered: action.dateRegistered.toDate().toISOString(), // Convert to ISO string
          emailVerified: action.emailVerified, 
          mount: true
        };
      
      case "USER_IS_NOT_ACTIVE":
        return {
          ...state,
          active: false,
          userId: "",
          email: "",
          username: "",
          likedTracks: [],
          dateRegistered: null, 
          emailVerified: false,
          mount: true
        };
      
      case "UPDATE_USER_PROFILE_SUCCESS":
        return {
          ...state,
          email: action.payload,
          error: null
        };
    
      case "UPDATE_USER_PROFILE_FAILURE":
        return {
          ...state,
          error: action.payload 
        };
   
    case "SAVE_TRACK_START":
      return { ...state, errorMessage: null };

    case "SAVE_TRACK_SUCCESS":
      return { ...state, likedTracks: action.likedTracks };

    case "SAVE_TRACK_ERROR":
      return { ...state, errorMessage: action.err };
      
      case "REMOVE_TRACK_START":
        return { ...state, errorMessage: null };
  
      case "REMOVE_TRACK_SUCCESS":
        return { ...state, likedTracks: action.likedTracks };
  
      case "REMOVE_TRACK_ERROR":
        return { ...state, errorMessage: action.err };

    default:
      return state;
  }
};

export default userReducer;
