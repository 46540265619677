import React, { useState } from 'react';
import { connect } from 'react-redux';
import { userForgotPassword } from '../store/actions/userActions';
import { useNavigate } from 'react-router-dom';
import Preloader from '../main/preloader';
import Modal from './modal';

const ForgotPassword = (props) => {
  const navigate = useNavigate();

  const [userdata, setUserData] = useState({ email: "" });
  const [errors, setErrors] = useState({ field: false, email: false });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserData({ ...userdata });
    const { email } = userdata;

    if (!validateEmail(email)) {
      return setErrors({ ...errors, email: true });
    }

    await props.forgotpassword(email, navigate); 

    if (!props.ForgotPasswordError) {
    //  setTimeout(() => navigate("/"), 2000);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setErrors({ field: false, email: false });
    setUserData({ ...userdata, [e.target.name]: value });
  };

  return (
    <div>
      {props.isFetching && <Preloader />}
      <Modal
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        errors={{ ...errors, forgotpassword: props.ForgotPasswordError }}
        type={"forgotpassword"}
        isFetching={props.isFetching}
        {...props}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.user.isFetching,
  ForgotPasswordError: state.user.ForgotPasswordError,
});

const mapDispatchToProps = (dispatch) => ({
  forgotpassword: (email, navigate) => dispatch(userForgotPassword(email, navigate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
