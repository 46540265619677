import React, { useState, useEffect } from "react";
import Modal from "./modal";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { userLogin } from "../store/actions/userActions";
import Preloader from "../main/preloader";

const Login = (props) => {
  const navigate = useNavigate();
  const [formType, setFormType] = useState("login");

  const [userdata, setUserData] = useState({
    email: "",
    password: ""
  });

  const [cred, setCred] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formType === "login") {
      const { email, password } = userdata;
      const credentials = await props.login(email, password);
      setCred(credentials);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setUserData({ ...userdata, [e.target.name]: value });
  };

  useEffect(() => {
    if (cred && !props.loginError) {
      navigate("/");
    }
  }, [cred, props.loginError, navigate]);

  return (
    <div>
      {props.isFetching && <Preloader />}
      <Modal
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        errors={{ login: props.loginError }}
        type={"login"}
        formType={formType}
        setFormType={setFormType}
        isFetching={props.isFetching}
        {...props}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.user.isFetching,
  loginError: state.user.loginError,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(userLogin(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
