import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Render from "./Render";
import { Link } from "react-router-dom";
import Skeleton from "./skeleton";
import navStyles from "./nav.module.scss";
import MenuBtn from "./menuBtn";
import logo from "../assets/logo.png";

const NavBar = (props) => {
  const [menuBtn, setMenuBtn] = useState({ isOpen: false });
  const navRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setMenuBtn({ isOpen: false });
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggle = () => {
    setMenuBtn({ isOpen: !menuBtn.isOpen });
  };

  return (
    <nav ref={navRef} className={navStyles.navWrapper}>
      <span className={navStyles.logoContainer}>
        <Link to="/">
          <img src={logo} alt="Logo" className={navStyles.logo} />
        </Link>
      </span>
      <Link to="/">
        <span className={navStyles.siteTitle}>
          <h1>CUBANA CHART</h1>
        </span>
      </Link>
      <span onClick={toggle} className={navStyles.menu}>
        <MenuBtn isOpen={menuBtn.isOpen} />
      </span>
      {!!props.statut.mount ? (
        <Render {...props} isOpen={menuBtn.isOpen} />
      ) : (
        <Skeleton />
      )}
    </nav>
  );
};

const mapStateToProps = (state) => {
  return { statut: state.statut };
};

export default connect(mapStateToProps)(NavBar);
