import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ChartByID from './exploreChartList';
import ExploreCharts from "./explore-charts";
import UserProfile from "./profile";
import AccountAction from "./account-action";
import About from "./about";
import Signup from "../modals/Signup";
import Login from "../modals/Login";
import ForgotPassword from "../modals/ForgotPassword";
import ResetPassword from "../modals/ResetPassword";
import ChartsList from "./chartsList";
import mainStyles from "./main.module.scss";
import UserFavs from "./userFav";
import TermsAndConditionsPage from "./terms-and-conditions";
import PrivacyPolicyPage from "./privacy-policy";
import { AnimatePresence, motion } from 'framer-motion';
import VerifyEmail from "./verify-email";

const pageTransition = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
};

const Main = ({ secured }) => {
  return (
    <div className={mainStyles.container}>
      <div className={mainStyles.wrapper}>
        <div style={{ position: "relative" }}>
          <AnimatePresence mode="wait">
            <Routes>
              <Route 
                path="/" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <ChartsList />
                  </motion.div>
                } 
              />
              <Route 
                path="/login" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <Login secured={secured} />
                  </motion.div>
                } 
              />
              <Route 
                path="/signup" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <Signup secured={secured} />
                  </motion.div>
                } 
              />
              <Route 
                path="/forgotpassword" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <ForgotPassword />
                  </motion.div>
                } 
              />
              <Route 
                path="/resetpassword" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <ResetPassword />
                  </motion.div>
                } 
              />
              <Route 
                path="/explore-charts" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <ExploreCharts />
                  </motion.div>
                } 
              />

              <Route 
                path="/account-action" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <AccountAction />
                  </motion.div>
                } 
              />
               
              <Route 
                path="/profile" 
                element={
                  secured ? (
                    <Navigate to="/login" />
                  ) : (
                    <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                      <UserProfile />
                    </motion.div>
                  )
                } 
              />
               <Route 
                path="/verify-email" 
                element={
                  secured ? (
                    <Navigate to="/login" />
                  ) : (
                    <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                      <VerifyEmail />
                    </motion.div>
                  )
                } 
              />
              
              <Route 
                path="/about" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <About />
                  </motion.div>
                } 
              />

              <Route 
                path="/terms-and-conditions" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <TermsAndConditionsPage />
                  </motion.div>
                } 
              />

              <Route 
                path="/privacy-policy" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <PrivacyPolicyPage />
                  </motion.div>
                } 
              />

              <Route 
                path="/chart/:chartId" 
                element={
                  <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                    <ChartByID />
                  </motion.div>
                } 
              />
              <Route 
                path="/user/saved-tracks" 
                element={
                  secured ? (
                    <Navigate to="/login" />
                  ) : (
                    <motion.div variants={pageTransition} initial="initial" animate="animate" exit="exit">
                      <UserFavs />
                    </motion.div>
                  )
                } 
              />
            </Routes>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Main;
