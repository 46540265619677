import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import './verify-email.css'; 

const AccountAction = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { mode, oobCode } = queryString.parse(location.search);

    if (mode === 'verifyEmail' && oobCode) {
      navigate(`/verify-email?oobCode=${oobCode}`);
    } else if (mode === 'resetPassword' && oobCode) {
      navigate(`/resetpassword?oobCode=${oobCode}`);
    } else {
      navigate('/login'); // Redirect to login if mode is not recognized or oobCode is missing
    }
  }, [location.search, navigate]);

  return (
    <div className="account-action-container">
      <p>Redirecting...<span className="loader"></span></p>
    </div>
  );
};

export default AccountAction;
