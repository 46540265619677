import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchChartByID } from '../store/actions/chartActions';
import chartListStyles from './chartList.module.scss';
import Skeleton from '../assets/skeleton';
import PlaylistTable from './playlistTable';
import { motion } from 'framer-motion';

const Container = motion.div;

const SkeletonContainer = () => (
  <div style={{ marginTop: '1em', height: '70vh', opacity: 0.2 }}>
    <Skeleton times={14} />
  </div>
);

const ChartByID = ({ currentChart, previousChart, loading, error, fetchChartByID, userSetTrack }) => {
  const { chartId } = useParams();

  useEffect(() => {
    if (chartId) {
      fetchChartByID(chartId);
    }
  }, [chartId, fetchChartByID]);

  // Ensure loading, error, and data checks are done correctly
  if (loading) {
    return (
      <div className={chartListStyles.tableContainer}>
        <div style={{ position: 'relative' }}>
          <SkeletonContainer />
        </div>
      </div>
    );
  }

  if (error) {
    return <div className={chartListStyles.err}>Network Connection Error: {error}</div>;
  }

  if (!currentChart) {
    return <div className={chartListStyles.err}></div>;
  }

  const { chartTitle: currentChartTitle, songs: latestSongs } = currentChart;
  const previousSongs = previousChart ? previousChart.songs : [];

  return (
    <Container>
      <h2>{currentChartTitle}</h2>
      <PlaylistTable
        latestSongs={latestSongs}
        previousSongs={previousSongs}
        userSetTrack={userSetTrack}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currentChart: state.chart.currentChart,
  previousChart: state.chart.previousChart,
  loading: state.chart.loading,
  error: state.chart.error
});

const mapDispatchToProps = {
  fetchChartByID,
  userSetTrack: (index) => ({ type: 'USER_SET_TRACK', index })
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartByID);
