
const initialState = {
  loading: false,
  allAds: [],
  error: null,
};

const adReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ALL_ADS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'FETCH_ALL_ADS_SUCCESS':
      return {
        ...state,
        loading: false,
        allAds: action.payload,
      };
    case 'FETCH_ALL_ADS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default adReducer;
