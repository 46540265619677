import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAds } from '../../store/actions/adAction';
import './adComponent.scss';

const AdComponent = React.memo(() => {
  const dispatch = useDispatch();
  const ads = useSelector((state) => state.ads.allAds);
  const adContainerRef = useRef(null);

  useEffect(() => {
    // Fetch ads when the component mounts
    dispatch(fetchAllAds());
  }, [dispatch]);

  useEffect(() => {
    if (ads && ads.length > 0) {
      // Filter out 'Native Banner' ads
      const filteredAds = ads.filter((ad) => ad.type !== 'Native Banner');

      if (filteredAds.length > 0) {
        // Select one random ad from the filtered ads
        const randomIndex = Math.floor(Math.random() * filteredAds.length);
        const selectedAd = filteredAds[randomIndex];

        if (selectedAd.type === 'Ad Network') {
         
          const adTag = selectedAd.adTag;
       
          if (adTag && adContainerRef.current) {
            // Clear the ad container
            adContainerRef.current.innerHTML = '';

            // Extract the `key` and `src` from the adTag
            const keyMatch = adTag.match(/'key'\s*:\s*'([^']+)'/);
            const srcMatch = adTag.match(/src=["']([^"']+)["']/);
            const key = keyMatch ? keyMatch[1] : null;
            const scriptSrc = srcMatch ? srcMatch[1] : null;

            if (key && scriptSrc) {
              // Create the initialization script (`atOptions`)
              const initScript = document.createElement('script');
              initScript.type = 'text/javascript';
              initScript.innerHTML = `
                atOptions = {
                  'key': '${key}',
                  'format': 'iframe',
                  'height': 90,
                  'width': 728,
                  'params': {}
                };
              `;
              adContainerRef.current.appendChild(initScript);

              // Create the ad script (`src`)
              const adScript = document.createElement('script');
              adScript.type = 'text/javascript';
              adScript.src = scriptSrc;
              adScript.async = true;

              adContainerRef.current.appendChild(adScript);

              // Cleanup on unmount
              return () => {
                adContainerRef.current.innerHTML = '';
              };
            }
          }
        } else if (selectedAd.type === 'Custom') {
          
          if (selectedAd.adTag) {
            // Inject a custom ad as an image with a clickable link
            adContainerRef.current.innerHTML = `
              <a href="${selectedAd.adTag}" target="_blank" rel="noopener noreferrer">
                <img class="responsive-ad-image" src="${selectedAd.adTag}" alt="Ad Banner" />
              </a>
            `;
          } else {
           
          }
        } else {
        //  console.warn('Unknown or unsupported ad type.');
        }
      } else {
       
      }
    } else {
     // console.log('No ads available to select from.');
    }
  }, [ads]);

  return (
    <div>
      <div
        className="adBanner"
        ref={adContainerRef}
        style={{ textAlign: 'center', margin: '10px 0' }}
      >
        {/* Ad will be dynamically injected here */}
      </div>
    </div>
  );
});

export default AdComponent;
